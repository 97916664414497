import React from "react";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { apiUtil, LoyaltySignUp as BsLoyaltySignUp } from "cb-design-system";
import { ReactReduxContext } from "react-redux";
import {
  AuthenticationState,
  authProviderClient,
  AzureADClient,
} from "../../AuthComponent";
import { authConfig } from "../../AuthProvider";
import axios from "axios";

const LoyaltySignUp = (props) => {
  const model = props.fields;

  const handleGetUserInfo = (email, apiUrl, token) => {
    if (!email) {
      window.location.href = window.location.origin;
      return;
    }

    const url = apiUrl.replace("{email}", email);

    return axios({
      method: "get",
      headers: {
        ...apiUtil.setHeaders(),
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: url,
    })
      .then((res) => {
        const queryRedirect =
          window.localStorage.getItem("LoyaltyLogInRedirectUrl") || "";
        window.localStorage.removeItem("LoyaltyLogInRedirectUrl");

        return {
          data: res?.data?.loyalty?.customers || {},
          redirectUrl: window.location.origin + queryRedirect,
        };
      })
      .catch(() => {
        return {
          data: null,
          redirectUrl: window.location.origin,
        };
      })
      .finally(() => {});
  };

  const handlePasswordReset = (email) => {
    authProviderClient.setAuthenticationParameters({
      authority: `${authConfig.domain}/${authConfig.mfaPasswordPolicy}`,
      loginHint: email,
    });

    authProviderClient.login();
  };

  return (
    <React.Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ error, accountInfo, logout, authenticationState }) => {
                if (AuthenticationState.InProgress === authenticationState) {
                  return <></>;
                }

                if (error && error?.errorMessage?.indexOf("AADB2C90118") >= 0) {
                  let errorArr = error.errorMessage.split("\r\n");
                  let email = "";

                  errorArr.forEach((e) => {
                    if (e.indexOf("login_hint:") >= 0) {
                      email = e.replace("login_hint:", "").trim();
                    }
                  });

                  handlePasswordReset(email);
                }

                if (error && error?.errorMessage?.indexOf("AADB2C90091") >= 0) {
                  // user cancelled
                }

                if (!error) {
                  const emailAddress = accountInfo?.account?.idToken?.signInName || null;

               

                  return (
                    <React.Fragment>
                      <BsLoyaltySignUp
                        store={context.store}
                        emailAddress={emailAddress}
                        token={accountInfo?.jwtIdToken}
                        model={model}
                        handleGetUserInfo={handleGetUserInfo}
                        handleLogout={logout}
                        authenticationState={authenticationState}
                      />
                    </React.Fragment>
                  );
                } else {
                  return <></>;
                }
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </React.Fragment>
  );
};

export default withSitecoreContext()(LoyaltySignUp);
