import React, { Fragment } from "react";
import { MobilePaySuccess as MobPaySuccess } from "cb-design-system";
import { connect, ReactReduxContext } from "react-redux";
import { authProviderClient, AzureADClient } from "../../AuthComponent";

const MobilePaySuccess = (props) => {
  return (
    <Fragment>
      <ReactReduxContext.Consumer>
        {((context) => {
          return (
            <AzureADClient
              forceLogin={false}
              provider={authProviderClient}
              reduxStore={context.store}
            >
              {({ authenticationState, error }) => {
                if (error != null) {
                  console.error(error);
                }

                return (
                  <MobPaySuccess
                    {...props}
                    authenticationState={authenticationState}
                    store={context.store}
                  />
                );
              }}
            </AzureADClient>
          );
        }).bind(this)}
      </ReactReduxContext.Consumer>
    </Fragment>
  );
};

export default MobilePaySuccess;
